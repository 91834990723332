/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BalanceDto {
  currency: string;
  amount: number;
  account: string;
}

export enum DealStatusDto {
  New = "New",
  Invest = "Invest",
  Committed = "Committed",
  Confirm = "Confirm",
  Payout = "Payout",
  Start = "Start",
  Active = "Active",
  Completed = "Completed",
  NotFinanced = "NotFinanced",
  Canceling = "Canceling",
  Cancelled = "Cancelled",
  Empty = "Empty",
  Rejected = "Rejected",
  Loss = "Loss",
  PartialLoss = "PartialLoss",
}

export interface RepaymentTransactionDto {
  id: string;

  /**
   * Date of the repayment
   * @format date-time
   */
  date: string;

  /** @format date-time */
  repaymentDate?: string;

  /** @format date-time */
  bookingDate?: string;

  /**
   * The principal amount in minor units (cents) that will be repaid. It has to be an integer.
   * @example 100
   */
  principal: number;

  /**
   * The interest amount in minor units (cents) that will be repaid. It has to be an integer
   * @example 10
   */
  interest: number;
  overdueInterest: number;

  /** Custom information about the repayment */
  info: string;

  /** status of repayment */
  status: "Projected" | "RepayTriggered" | "Repaid" | "Loss";
}

export interface RepaymentDto {
  /**
   * The id of the repayment.
   * @example 1
   */
  id: string;

  /**
   * Date of the repayment
   * @format date-time
   */
  date: string;

  /** The day of the repayment, relative to the start date of the deal. */
  day: number;

  /** @format date-time */
  repaymentDate?: string;

  /** @format date-time */
  bookingDate?: string;

  /**
   * The principal amount in minor units (cents) that will be repaid. It has to be an integer.
   * @example 100
   */
  principal: number;

  /**
   * The interest amount in minor units (cents) that will be repaid. It has to be an integer
   * @example 10
   */
  interest: number;
  overdueInterest: number;
  lostPrincipal: number;
  lostInterest: number;
  lostOverdueInterest: number;

  /** Custom information about the repayment */
  info: string;

  /** status of repayment */
  status:
    | "Projected"
    | "Pending"
    | "PartialPending"
    | "RepayTriggered"
    | "PartialRepayTriggered"
    | "Repaid"
    | "PartiallyRepaid"
    | "Confirmed"
    | "Loss"
    | "PartialLoss";
  transactions: RepaymentTransactionDto[];
}

export interface ConfigurationDto {
  /**
   * The max number of investors that can participate in this deal
   * @example 25
   */
  maxInvestors?: number | null;

  /**
   * The min investment
   * @example 10
   */
  minInvestment?: number;

  /**
   * Available increase step for investment
   * @example 25
   */
  investmentStep?: number;
  secondaryMarketDisabled?: boolean;
  pricingStrategy?: "INTEREST" | "CASHFLOW";
}

export interface ShareTransactionDto {
  id: string;
  relatedId: string | null;
  repTransactionId: string | null;
  principal: number;
  status: "Booked" | "Loss" | "Pending" | "Projected";
  interest: number;
  overdueInterest: number;
  fee: number;
  lateFee: number;
  overdueFee: number;
  other: number;
  total: number;
  totalIncome: number;
  totalExpenses: number;
  type: "RepaymentPlan" | "Repayment" | "Fee" | "Buy" | "Sale" | "LateFee" | "Default";

  /** @format date-time */
  date?: string;

  /** @format date-time */
  createdDate?: string;
  description?: string;
}

export interface PricingInfoDto {
  appliedVersion: string;
  isInvestorOverride: boolean;
  value: object;
  strategyType: "INTEREST" | "CASHFLOW" | "SECONDARY_MARKET";
}

export interface ShareRepaymentTransactionDto {
  transaction: ShareTransactionDto;
  isPaid: boolean;
  isBooked: boolean;
  isLost: boolean;
  daysInArrears: number;
  partialPayment: boolean;
  remainingPrincipal: number;
}

export interface ShareCommitmentDto {
  amount: number;
  totalAmount: number;
  transactions: ShareTransactionDto[];
  confirmed: boolean;
  canceled: boolean;
  share?: ShareDto;
  id: string;
  investorId: string;

  /** @format date-time */
  date: string;
  addedBy: "USER" | "ADMIN" | "IMPORT" | null;
  xirr: number;
  expectedInterest: number;
  expectedFee: number;
  pricingInfo?: PricingInfoDto;
  repayments?: ShareRepaymentTransactionDto[];
}

export interface ShareSaleDto {
  saleAmount: number;
  id: string;
  providerId: string;
  dealId: string;
  investorId: string;
  shareInitialOutstandingAmount: number;
  priceMultiplier: number;
  multiplierAmount: number;
  latestCalculatedPrice: number;
  accruedInterest: number;
  potentialInterest: number;
  sellerFeeAmount: number;
  buyerFeeAmount?: number;

  /** @format date-time */
  latestCalculatedPriceDate: string;
  boughtByInvestor: string;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  updatedDate: string;

  /** @format date-time */
  soldDate: string;
  status: "Open" | "Cancelled" | "Processing" | "Sold";
}

export type PricingInfo = object;

export interface ShareDto {
  amount: number;
  initialAmount: number;
  commitments: ShareCommitmentDto[];
  transactions: ShareTransactionDto[];
  dealId: string;
  dealProviderId: string;
  currency: "CHF" | "EUR";
  isDealProviderShare: boolean;
  status: string;
  committedInvestorsCount: number;
  outstandingAmount: number;
  unpaidAmount: number;

  /** @format date-time */
  createdDate: string;
  shareSales: ShareSaleDto[];
  pricingInfo: PricingInfo;
  id: string;
  investorId: string;

  /** @format date-time */
  date: string;
  addedBy: "USER" | "ADMIN" | "IMPORT" | null;
  xirr: number;
  expectedInterest: number;
  expectedFee: number;
  repayments?: ShareRepaymentTransactionDto[];
}

export interface PublicationDto {
  /**
   * Date of the publication
   * @format date-time
   * @example 2021-08-30T09:37:25.254Z
   */
  publishDate: string;

  /**
   * Numerical investor group. Empty value mean that publication is visible for all
   * @example 1
   */
  group?: number;
}

export interface LabelDto {
  id: string;

  /**
   * Label
   * @example important
   */
  label: string;

  /**
   * Group
   * @example underwriting
   */
  group: string;
}

export interface TagDto {
  id: string;

  /**
   * Tag's key
   * @example key
   */
  key: string;

  /**
   * Tag's value
   * @example value
   */
  value: string;

  /**
   * Group
   * @example underwriting
   */
  group: string;
}

export interface DealEventDto {
  id?: string;

  /** @format date-time */
  createdDate: string;
  type: string;
  json: object;
  custom?: boolean;
  dealId: string;
  providerId: string;
}

export interface DealBaseDto {
  /**
   * The deal status.
   * @example New
   */
  status: DealStatusDto;

  /**
   * The unique identifier of the deal provider
   * @example 1
   */
  id: string;

  /**
   * The deal provider id. This provider should be available and configured already.
   * @example CG24
   */
  providerId: string;

  /**
   * Description of the deal
   * @example 36 months loan, for 3% interest rate
   */
  description: string;

  /**
   * The required amount for this deal
   * @example 100
   */
  principal: number;

  /**
   * The total interest amount
   * @example 10
   */
  interest: number;

  /**
   * The nominal interest rate
   * @example 0.1
   */
  nominalInterestRate: number;

  /**
   * The currency of the deal
   * @example CHF
   */
  currency: "CHF" | "EUR";

  /**
   * The name of the client
   * @example BestCompany
   */
  clientName: string;

  /**
   * The duration of the deal
   * @example 24
   */
  duration: number;

  /**
   * The start date of the deal
   * @format date-time
   * @example 2021-08-30T09:37:25.254Z
   */
  startDate: string;

  /**
   * The end date of the deal
   * @format date-time
   * @example 2023-08-30T09:37:25.254Z
   */
  endDate: string;

  /**
   * The loss date of the deal
   * @format date-time
   * @example 2022-08-30T09:37:25.254Z
   */
  lossDate: string;

  /** The repayment schedule */
  repayments: RepaymentDto[];
  configuration: ConfigurationDto;
  dealProviderShare?: ShareDto;
  shares: ShareDto[];
  publications: PublicationDto[];
  labels: LabelDto[];
  tags: TagDto[];
  events: DealEventDto[];
  financeShare: number;
  cancelReason: string;
}

export enum Iso2CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export interface AddressDto {
  country: Iso2CountryCode;
  street: string;
  zipcode: string;
  city: string;
  streetNr?: string;
}

export interface InvestorUserAccessDto {
  userId: string;
  role: "investor:owner" | "investor:admin" | "investor:advisor" | "investor:viewonly";
  isPrimaryAccount: boolean;
  name: string;
  email: string;
  phone?: string | null;
  investor?: InvestorDto;
}

export interface InvestorDto {
  iban: string;
  id: string;
  name: string;
  accountType: "INDIVIDUAL_ACCOUNT" | "JOINT_ACCOUNT" | "INSTITUTIONAL";
  address: AddressDto;
  description: string;

  /**
   * Numerical investor group. Empty value mean that publication is visible for all
   * @example 1
   */
  group?: number;
  isCompany: boolean;
  email: string;
  blockedFromInvestingReason: string;
  blockedFromSellingReason: string;

  /** Which users have access to this investor */
  userAccess: InvestorUserAccessDto[];
}

export type UpdateInvestorDto = object;

export interface CollateralDto {
  type:
    | "NONE"
    | "INHABERSCHULDBRIEF"
    | "REGISTERSCHULDBRIEF"
    | "SOLIDARBÜRGSCHAFT"
    | "MIETZINSABTRETUNG"
    | "UNWIDERRUFLICHES_ZAHLUNGSVERSPRECHEN"
    | "AKTIENPFAND"
    | "CORPORATE_GUARANTEE"
    | "BANKGARANTIE"
    | "DEBITORENZESSION"
    | "VERPFÄNDUNG_LEBENSVERSICHERUNG"
    | "VERPFÄNDUNG_TODESFALLVERSICHERUNG"
    | "VERPFÄNDUNG_WS_DEPOT"
    | "VERPFÄNDUNG_SPAREN_3"
    | "VERPFÄNDUNG_PENSIONSKASSE"
    | "VERPFÄNDUNG_FZ_KONTO";
  place: string;
  estimate: number;
  currency?: "CHF" | "EUR";
  vorgangSchuldbrief?: number;
  rangOriginator?: number;
  rangBetrag?: number;
  totalAmount?: number;
  ltv?: number;
}

export interface FinancialRatiosDto {
  dateRanges: string[][];
  data: object;
}

export interface DealRiskDto {
  /**
   * Deal type
   * @example CORPORATE
   */
  productType: "CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC";

  /**
   * The purpose of the deal
   * @example EDUCATION
   */
  purpose:
    | "EXPANSION"
    | "RESEARCH_DEVELOPMENT"
    | "NONE"
    | "EDUCATION"
    | "CURRENT_ASSETS"
    | "INVESTMENT_PROPERTY_REMODELLING"
    | "REAL_ESTATE"
    | "MEDICAL_COSTS"
    | "INVESTMENT_PROPERTY"
    | "RELOCATION"
    | "CONSUMER_ELECTRONICS"
    | "VACATION"
    | "FURNITURE"
    | "WEDDING"
    | "RENOVATION"
    | "LIQUIDITY"
    | "INVESTMENT_ASSETS"
    | "CAR"
    | "INVESTMENT_PROPERTY_PURCHASE"
    | "CONVERSION"
    | "HOUSEHOLD_AIDS"
    | "OTHER"
    | "REFINANCING"
    | "INVOICE_PREFINANCING";

  /**
   * The rating of the deal
   * @example A
   */
  rating: "AAA" | "AA" | "A" | "B" | "C" | "D" | "E";

  /** The insurance coverage if the deal */
  insurance: string;

  /** The collaterals of the deal */
  collaterals?: CollateralDto[];
  genericData?: object;
  riskTeamComment?: string;
  financialRatios?: FinancialRatiosDto;
}

export interface DealBorrowerDto {
  marketplaceId: string;
  originatorId: string;
  industry: string;
  legalForm: string;

  /** @format date-time */
  incorporationDate: string;
  rating: string;
  country: string;
  region: string;

  /** @format date-time */
  firstPayoutDate: string;
  paymentHistory: string;
}

export interface IDealDto {
  /**
   * The deal status.
   * @example New
   */
  status: DealStatusDto;

  /**
   * The unique identifier of the deal provider
   * @example 1
   */
  id: string;

  /**
   * The deal provider id. This provider should be available and configured already.
   * @example CG24
   */
  providerId: string;

  /**
   * Description of the deal
   * @example 36 months loan, for 3% interest rate
   */
  description: string;

  /**
   * The required amount for this deal
   * @example 100
   */
  principal: number;

  /**
   * The total interest amount
   * @example 10
   */
  interest: number;

  /**
   * The nominal interest rate
   * @example 0.1
   */
  nominalInterestRate: number;

  /**
   * The currency of the deal
   * @example CHF
   */
  currency: "CHF" | "EUR";

  /**
   * The name of the client
   * @example BestCompany
   */
  clientName: string;

  /**
   * The duration of the deal
   * @example 24
   */
  duration: number;

  /**
   * The start date of the deal
   * @format date-time
   * @example 2021-08-30T09:37:25.254Z
   */
  startDate: string;

  /**
   * The end date of the deal
   * @format date-time
   * @example 2023-08-30T09:37:25.254Z
   */
  endDate: string;

  /**
   * The loss date of the deal
   * @format date-time
   * @example 2022-08-30T09:37:25.254Z
   */
  lossDate: string;

  /** The repayment schedule */
  repayments: RepaymentDto[];
  configuration: ConfigurationDto;
  dealProviderShare?: ShareDto;
  shares: ShareDto[];
  publications: PublicationDto[];
  labels: LabelDto[];
  tags: TagDto[];
  events: DealEventDto[];
  financeShare: number;
  cancelReason: string;
  risk: DealRiskDto;
  borrower: DealBorrowerDto;
  projectedXirr: number;
}

export interface DealID {
  dealId: string;
  providerId: string;
}

export interface DealsListDto {
  deals: DealID[];
  withXirr?: boolean;
}

export interface DealProviderDto {
  id: string;
  description?: string;
  ratingDescription?: string;
}

export interface FilterDto {
  providers: DealProviderDto[];
}

export enum ShareStatus {
  Unpaid = "Unpaid",
  Paid = "Paid",
  Active = "Active",
  Overdue30Days = "Overdue30Days",
  Overdue60Days = "Overdue60Days",
  Overdue120Days = "Overdue120Days",
  TechnicalDefault = "TechnicalDefault",
  Finished = "Finished",
  Cancelled = "Cancelled",
  Loss = "Loss",
  PartialLoss = "PartialLoss",
}

export interface DealDto {
  /**
   * The deal status.
   * @example New
   */
  status: DealStatusDto;
  risk: DealRiskDto;
  borrower: DealBorrowerDto;

  /**
   * The unique identifier of the deal provider
   * @example 1
   */
  id: string;

  /**
   * The deal provider id. This provider should be available and configured already.
   * @example CG24
   */
  providerId: string;

  /**
   * Description of the deal
   * @example 36 months loan, for 3% interest rate
   */
  description: string;

  /**
   * The required amount for this deal
   * @example 100
   */
  principal: number;

  /**
   * The total interest amount
   * @example 10
   */
  interest: number;

  /**
   * The nominal interest rate
   * @example 0.1
   */
  nominalInterestRate: number;

  /**
   * The currency of the deal
   * @example CHF
   */
  currency: "CHF" | "EUR";

  /**
   * The name of the client
   * @example BestCompany
   */
  clientName: string;

  /**
   * The duration of the deal
   * @example 24
   */
  duration: number;

  /**
   * The start date of the deal
   * @format date-time
   * @example 2021-08-30T09:37:25.254Z
   */
  startDate: string;

  /**
   * The end date of the deal
   * @format date-time
   * @example 2023-08-30T09:37:25.254Z
   */
  endDate: string;

  /**
   * The loss date of the deal
   * @format date-time
   * @example 2022-08-30T09:37:25.254Z
   */
  lossDate: string;

  /** The repayment schedule */
  repayments: RepaymentDto[];
  configuration: ConfigurationDto;
  dealProviderShare?: ShareDto;
  shares: ShareDto[];
  publications: PublicationDto[];
  labels: LabelDto[];
  tags: TagDto[];
  events: DealEventDto[];
  financeShare: number;
  cancelReason: string;
}

export interface InvestmentWithDealDto {
  status: ShareStatus;
  id: string;
  investorId: string;
  deal: DealDto;
  amount: number;
  outstandingAmount: number;
  unpaidAmount: number;
  xirr: number;
  actualXirr?: number;
  interest: number;
  overdueInterest: number;
  sellDisabledReason?: string;
  expectedInterest: number;
  fee: number;
  overdueFee: number;
  expectedFee: number;
  transactions: ShareTransactionDto[];
  repayments: ShareRepaymentTransactionDto[];
  type?: "Share" | "Commitment";
  addedBy: "USER" | "ADMIN" | "IMPORT";

  /** @format date-time */
  date: string;
  pricingInfo?: PricingInfoDto;
  shareSales: ShareSaleDto[];
  canceled?: boolean;
}

export enum DealStatus {
  New = "New",
  Invest = "Invest",
  Committed = "Committed",
  Confirm = "Confirm",
  Payout = "Payout",
  Start = "Start",
  Active = "Active",
  Completed = "Completed",
  Loss = "Loss",
  PartialLoss = "PartialLoss",
  NotFinanced = "NotFinanced",
  Cancelled = "Cancelled",
  Rejected = "Rejected",
}

export interface RepaymentDetailsInfoDto {
  /** @format date-time */
  nextRepaymentDate: string | null;
  nextRepaymentAmount: number | null;

  /** @format date-time */
  actualLastRepaymentDate: string | null;

  /** @format date-time */
  scheduledLastRepaymentDate: string | null;
  repaymentAmountInDelay: number;

  /** @format date-time */
  firstMissedRepaymentDate: string | null;
}

export interface RepaymentDetailsDto {
  repayments: RepaymentDto[];
  info: RepaymentDetailsInfoDto;
}

export interface CreateInvestmentDto {
  amount: number;
}

export interface CreateShareSaleDto {
  saleAmount: number;
  priceMultiplier: number;
}

export interface ShareSalePriceDto {
  priceBeforeFees: number;
  accruedInterest: number;
  multiplierAmount: number;
  nominalAmount: number;
  sellerFeeAmount: number;
  buyerFeeAmount?: number;
}

export interface ShareSaleWithShareAndDealDto {
  shareWithDeal: InvestmentWithDealDto;
  shareSale: ShareSaleDto;
}

export interface BulkCreateInvestment {
  dealId: string;
  providerId: string;
  amount: number;
  currency: "CHF" | "EUR";
}

export interface BulkCreateInvestmentsDto {
  investments: BulkCreateInvestment[];
}

export interface BulkRequest {
  dealId: string;
  providerId: string;
  amount: number;
  fee?: number;
  currency: "CHF" | "EUR";
}

export interface BulkResult {
  request: BulkRequest;
  deal: DealDto;
  response: ShareCommitmentDto;
}

export interface HttpExceptionDto {
  name: string;
  status: number;
  message: string;
}

export interface BulkError {
  request: BulkRequest;
  deal: DealDto | null;
  response: HttpExceptionDto;
}

export interface BulkCreateInvestmentsResponseDto {
  result: BulkResult[];
  errors: BulkError[];
}

export interface TransactionsInfoDto {
  transactions: ShareTransactionDto[];
  pricingInfo: PricingInfoDto;
  xirr: number;
}

export interface UserInviteDto {
  email: string;
  firstName: string;
  lastName?: string;
  role: "investor:owner" | "investor:admin" | "investor:advisor" | "investor:viewonly";
}

export interface UserInvestorAssignmentDto {
  newOwnerId?: string;
}

export interface InvestorAccountChangeRoleDto {
  role: "investor:owner" | "investor:admin" | "investor:advisor" | "investor:viewonly";
  newOwnerId?: string;
}

export interface InvestorAccountInheritorsDto {
  lastOwner: boolean;
  inheritors: InvestorUserAccessDto[];
}

export interface DealFiltersDto {
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
}

export interface UserDealFilterDto {
  name: string;
  filters: DealFiltersDto;
  enableNotifications?: boolean;
}

export interface UpsertUserDealFiltersBulkDto {
  filters: UserDealFilterDto[];
}

export interface DealMatchesFilterDto {
  id: string;
  providerId: string;
  currency: "CHF" | "EUR";
  productType: "CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC";
  rating: "AAA" | "AA" | "A" | "B" | "C" | "D" | "E";
  principal: number;
  outstandingAmount: number;
  shares: ShareDto[];
  filterName: string;
}

export interface GeneralStatisticsResultDto {
  upcomingInterest: Record<string, number>;
  upcomingPrincipal: Record<string, number>;
  principalOnTime: Record<string, number>;
  principalBelow31: Record<string, number>;
  principalAbove31: Record<string, number>;
  irrs: Record<string, number>;
}

export interface ProfitLossStatisticsResultDto {
  profits: Record<string, number>;
  losses: Record<string, number>;
  totals: Record<string, number>;
  irrs: Record<string, number>;
}

export interface TransactionRepaymentDto {
  id: string;
  relatedId: string | null;
  repTransactionId: string | null;
  principal: number;
  status: "Booked" | "Loss" | "Pending" | "Projected";
  interest: number;
  overdueInterest: number;
  fee: number;
  lateFee: number;
  overdueFee: number;
  other: number;
  total: number;
  totalIncome: number;
  totalExpenses: number;
  type: "RepaymentPlan" | "Repayment" | "Fee" | "Buy" | "Sale" | "LateFee" | "Default";

  /** @format date-time */
  date?: string;

  /** @format date-time */
  createdDate?: string;
  description?: string;
  repayment: RepaymentDto;
}

export interface RepaymentStatisticsResultDto {
  /** @format date-time */
  date: string;
  repayments: TransactionRepaymentDto[];
}

export interface RepaymentStatisticsWithShareDto {
  share: number;
  principal: number;
  interest: number;
  total: number;
}

export interface RepaymentStatisticsDto {
  principal: number;
  interest: number;
  total: number;
}

export interface InvestmentSummaryResultDto {
  performing: RepaymentStatisticsWithShareDto;
  arrears30: RepaymentStatisticsWithShareDto;
  arrears60: RepaymentStatisticsWithShareDto;
  arrears119: RepaymentStatisticsWithShareDto;
  technicalDefault: RepaymentStatisticsWithShareDto;
  repaid: RepaymentStatisticsDto;
  loss: RepaymentStatisticsDto;
}

export interface PortfolioAllocationResultDto {
  productTypeSum: Record<string, { count: number; sum: number }>;
  ratingSum: Record<string, { count: number; sum: number }>;
  statusSum: Record<string, { count: number; sum: number }>;
}

export interface TransactionDto {
  id: number;
  eventType: string;
  type?: string;
  method?: string;
  currency: "CHF" | "EUR";
  amount: number;

  /** @format date-time */
  transferDueDate?: string;

  /** @format date-time */
  date?: string;

  /** @format date-time */
  createdDate: string;
}

export interface UserDto {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string | null;
  accounts?: InvestorUserAccessDto[];
  salutation: "Mr" | "Ms" | "Mx";
  birthday: number | null;
  phone: string | null;
  country: string | null;
  nationality: string | null;
  city: string | null;
  street: string | null;
  streetNumber: string | null;
  zipCode: string | null;
  language: "de-CH" | "en-CH" | "fr-CH" | "it-CH" | "acholi" | null;
  acceptedTermsAndConditions: boolean;
}

export interface BankAccountCreateDTO {
  iban: string;
  currency: "CHF" | "EUR";
}

export interface CreateInvestorDto {
  id?: string;
  bankAccounts?: BankAccountCreateDTO[];
  name: string;
  accountType: "INDIVIDUAL_ACCOUNT" | "JOINT_ACCOUNT" | "INSTITUTIONAL";
  address: AddressDto;
  description: string;

  /**
   * Numerical investor group. Empty value mean that publication is visible for all
   * @example 1
   */
  group?: number;
  isCompany: boolean;
  email: string;
  blockedFromInvestingReason?: string;
  blockedFromSellingReason?: string;
  acceptedSolidarityAgreement?: boolean;
  key?: string;
  advanonId?: string;
  additionalInfo?: object;
}

export type Address = object;

export interface InvestorUserAccess {
  investorId: string;
  userId: string;
  isPrimaryAccount: boolean;
  role: "investor:owner" | "investor:admin" | "investor:advisor" | "investor:viewonly";
  investor: Investor;
  name: string;
  email: string;
  dealFilters?: object;
  portfolioFilters?: object;
  phone?: string;
  signatureRights?: "COLLECTIVE_SIGNATURE" | "NO_SIGNATURE" | "SINGLE_SIGNATURE";

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
}

export interface Investor {
  id: string;
  name: string;
  accountType: "INDIVIDUAL_ACCOUNT" | "JOINT_ACCOUNT" | "INSTITUTIONAL";
  address: Address;
  description?: string;
  group?: number;
  email: string;
  isCompany: boolean;
  key?: string;
  blockedFromInvestingReason?: string;
  blockedFromSellingReason?: string;
  acceptedSolidarityAgreement?: boolean;
  userAccess: InvestorUserAccess[];
  advanonId?: string;
  additionalInfo: object;
}

export interface UserUpdateDto {
  country?: Iso2CountryCode | null;
  firstName?: string;
  lastName?: string | null;
  salutation?: "Mr" | "Ms" | "Mx";
  birthday?: number | null;
  phone?: string | null;
  nationality?: string | null;
  city?: string | null;
  street?: string | null;
  streetNumber?: string | null;
  zipCode?: string | null;
  language?: "de-CH" | "en-CH" | "fr-CH" | "it-CH" | "acholi" | null;
}

export interface ChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export interface InvestorIdDto {
  investorId: string;
}

export interface UserRemoveAccountDto {
  investorId: string;
  newOwner?: string;
}

export interface ChangeUserEmailDto {
  email: string;
}

export interface VerifyChangeUserEmailDto {
  token: string;
}

export interface Country {
  capital: string;
  continent: string;
  currency: string;
  emoji: string;
  emojiU: string;
  languages: string[];
  name: string;
  native: string;
  phone: string;
  shortName: string;
}

export interface AddressEntity {
  country: Country;
  street: string;
  zipcode: string;
  city: string;
  streetNr: string;
  confirmedInvalid: boolean;
}

export interface ContactPerson {
  title: "MR" | "MS" | "MX";
  nationality: Country;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  address: AddressEntity;
  addressVerifiedAt: string;
  phoneNumber: string;
  isBeneficialOwner: boolean;
  individualSigningAuthority: boolean;
  contactPersonDataUserConfirmation: boolean;
}

export interface KycRecord {
  category?:
    | "FAMILY_OFFICE"
    | "UHNWI"
    | "HNWI"
    | "RETAIL_INVESTOR"
    | "OTHER_PRIVATE"
    | "ASSET_MANAGER"
    | "MUTUAL_FUND"
    | "PENSION_FUND"
    | "HEDGE_FUND"
    | "OTHER_FUND"
    | "BANK"
    | "OTHER_FINANCIAL_INSTITUTION"
    | "TRUST"
    | "OTHER"
    | "SENIOR_INVESTOR"
    | "JUNIOR_INVESTOR";
  id: string;
  type: string;
  tenantId: string;
  tenant: object;
  pepCheckHistory: object[];
  bankVerificationHistory: object[];
  adminApprovalHistory: object[];
  oidIdentificationHistory: object[];
  continued: boolean;
  createdAt: string;
  updatedAt: string;
  beneficialOwnerStatus: "HOLDING_MORE_THAN_25_SHARES" | "CONTROLLING_IN_OTHER_WAYS" | "MANAGING_DIRECTORS" | null;
  beneficialOwners?: BeneficialOwner[];
  migrated: boolean;
  contactPerson: ContactPerson;
}

export interface BeneficialOwner {
  nationality: Country;
  id: number;
  kycRecordId: string;
  kycRecord: KycRecord;
  firstName: string;
  lastName: string;
  title: object;
  dateOfBirth: string;
  address: AddressEntity;
  addressVerifiedAt: string;
  beneficialOwnerDataUserConfirmation: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface PrivateKycRecord {
  beneficialOwnerStatus: "HOLDING_MORE_THAN_25_SHARES" | "CONTROLLING_IN_OTHER_WAYS" | "MANAGING_DIRECTORS";
  category?:
    | "FAMILY_OFFICE"
    | "UHNWI"
    | "HNWI"
    | "RETAIL_INVESTOR"
    | "OTHER_PRIVATE"
    | "ASSET_MANAGER"
    | "MUTUAL_FUND"
    | "PENSION_FUND"
    | "HEDGE_FUND"
    | "OTHER_FUND"
    | "BANK"
    | "OTHER_FINANCIAL_INSTITUTION"
    | "TRUST"
    | "OTHER"
    | "SENIOR_INVESTOR"
    | "JUNIOR_INVESTOR";
  politicalExposure: "yes" | "no";
  privateAnnualIncome: "LESS_100K" | "100K_200K" | "200K_500K" | "MORE_500K";
  employmentStatus: "EMPLOYED" | "INDEPENDENT" | "STUDENT" | "PENSIONER" | "IV_PENSIONER" | "UNEMPLOYED";
  educationLevel:
    | "BASIC_EDUCATION"
    | "VOCATIONAL"
    | "SECONDARY_EDUCATION"
    | "HIGHER_VOCATIONAL"
    | "BACHELOR"
    | "MASTER_DR";
  privatePlannedInvestment: "LESS_100K" | "100K_250K" | "MORE_250K";
  privateNetWorth: "LESS_100K" | "100K_500K" | "500K_2M" | "MORE_2M";
  personalDataUserConfirmation: boolean;
  employmentJobTitle: string;
  employmentEmployer: string;
  moneyOrigination: string;
  contactPerson: ContactPerson;
  beneficialOwners: BeneficialOwner[];
  id: string;
  type: string;
  tenantId: string;
  tenant: object;
  pepCheckHistory: object[];
  bankVerificationHistory: object[];
  adminApprovalHistory: object[];
  oidIdentificationHistory: object[];
  continued: boolean;
  createdAt: string;
  updatedAt: string;
  migrated: boolean;
}

export interface BusinessKycRecord {
  migrated: boolean;
  category?:
    | "FAMILY_OFFICE"
    | "UHNWI"
    | "HNWI"
    | "RETAIL_INVESTOR"
    | "OTHER_PRIVATE"
    | "ASSET_MANAGER"
    | "MUTUAL_FUND"
    | "PENSION_FUND"
    | "HEDGE_FUND"
    | "OTHER_FUND"
    | "BANK"
    | "OTHER_FINANCIAL_INSTITUTION"
    | "TRUST"
    | "OTHER"
    | "SENIOR_INVESTOR"
    | "JUNIOR_INVESTOR";
  politicalExposure: "yes" | "no";
  businessPlannedInvestment: "LESS_100K" | "100K_250K" | "MORE_250K";
  businessAnnualIncome: "LESS_100K" | "100K_1M" | "MORE_1M";
  businessNetWorth: "LESS_100K" | "100K_1M" | "MORE_1M";
  beneficialOwnerStatus: "HOLDING_MORE_THAN_25_SHARES" | "CONTROLLING_IN_OTHER_WAYS" | "MANAGING_DIRECTORS";
  companyName: string;
  companyUID: string;
  industry: string;
  legalStructure: string;
  address: AddressEntity;
  addressVerifiedAt: string;
  moneyOrigination: string;
  contactPerson: ContactPerson;
  beneficialOwners: BeneficialOwner[];
  id: string;
  type: string;
  tenantId: string;
  tenant: object;
  pepCheckHistory: object[];
  bankVerificationHistory: object[];
  adminApprovalHistory: object[];
  oidIdentificationHistory: object[];
  continued: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ValidationResponseDto {
  property: string;
  children: ValidationResponseDto[];
  error?: object;
}

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
  descriptor?: ValidationResponseDto[];
}

export interface InvestorApiControllerGetDealsParams {
  excludeDeals?: any[];
  pageSize: number;
  pageIndex: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  withXirr?: boolean;
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
}

export interface InvestorApiControllerGetDealsByContinuationTokenParams {
  excludeDeals?: any[];
  continuationToken?: string;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  withXirr?: boolean;
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
}

export interface InvestorApiControllerGetDealParams {
  skipRisk: boolean;
  loadBorrower: boolean;
  providerId: string;
  id: string;
}

export interface InvestorApiControllerGetSharesParams {
  productType: string[];
  rating: string[];

  /** @example ["New","Invest","Committed","Confirm","Payout","Start","Active","Completed","Loss","PartialLoss","NotFinanced","Cancelled","Rejected"] */
  status?: string[];
  shareStatus: string[];
  minDuration: number;
  maxDuration: number;
  currency: string[];
  minPrincipal: number;
  maxPrincipal: number;
  date: string;
  minAmount: number;
  maxAmount: number;
  minXirr: number;
  maxXirr: number;
  minOutstandingAmount: number;
  maxOutstandingAmount: number;
  sortBy: string;
  sortOrder: string;
  pageIndex?: number;
  pageSize?: number;
  sort?: string;
}

export interface InvestorApiControllerGetCommitmentsParams {
  /** @example ["New","Invest","Committed","Confirm","Payout","Start","Active","Completed","Loss","PartialLoss","NotFinanced","Cancelled","Rejected"] */
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize?: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  sort?: string;
}

export interface InvestorApiControllerGetCommitmentsByContinuationTokenParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  continuationToken?: string;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
}

export interface InvestorApiControllerDownloadCommitmentsParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize: number;
  pageIndex: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
}

export interface InvestorApiControllerGetInvestmentsParams {
  /** @example ["New","Invest","Committed","Confirm","Payout","Start","Active","Completed","Loss","PartialLoss","NotFinanced","Cancelled","Rejected"] */
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize?: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
  sort?: string;
}

export interface InvestorApiControllerGetInvestmentsByContinuationTokenParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  continuationToken?: string;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
}

export interface InvestorApiControllerDownloadInvestmentsParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize: number;
  pageIndex: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
  unpaidSeparate?: boolean;
}

export interface InvestorApiControllerGetInvestmentParams {
  skipRisk: boolean;
  loadBorrower: boolean;
  investmentId: string;
}

export interface InvestorApiControllerGetInvestmentEventsParams {
  types: string[];
  investmentId: string;
}

export interface InvestorApiControllerGetShareSaleSellerPriceParams {
  saleAmount: number;
  priceMultiplier: number;
  providerId: string;
  id: string;
}

export interface InvestorApiControllerGetSecondarymarketOffersParams {
  /** @example ["New","Invest","Committed","Confirm","Payout","Start","Active","Completed","Loss","PartialLoss","NotFinanced","Cancelled","Rejected"] */
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize?: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
  sort?: string;
}

export interface InvestorApiControllerGetSecondarymarketOffersByContinuationTokenParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  continuationToken?: string;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
}

export interface InvestorApiControllerGetInvestorsSecondarymarketOffersParams {
  /** @example ["New","Invest","Committed","Confirm","Payout","Start","Active","Completed","Loss","PartialLoss","NotFinanced","Cancelled","Rejected"] */
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  pageSize?: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
  sort?: string;
}

export interface InvestorApiControllerGetInvestorsSecondarymarketOffersByContinuationTokenParams {
  status?: DealStatus[];
  shareStatus?: ShareStatus[];
  continuationToken?: string;
  sortBy?: string;
  sortOrder?: "DESC" | "ASC";
  productType?: ("CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC")[];
  rating?: ("AAA" | "AA" | "A" | "B" | "C" | "D" | "E")[];
  minDuration?: number;
  maxDuration?: number;
  currency?: ("CHF" | "EUR")[];
  minPrincipal?: number;
  maxPrincipal?: number;
  minAmount?: number;
  maxAmount?: number;
  minXirr?: number;
  maxXirr?: number;
  minOutstandingAmount?: number;
  maxOutstandingAmount?: number;
  date?: string;
  minDate?: string;
  maxDate?: string;
  havingSaleStatus?: ("Open" | "Cancelled" | "Processing" | "Sold")[];
  providerId?: string;
  dealId?: string;
  clientName?: string;
  ids?: string[];
}

export interface InvestorApiControllerGetExpectedXirrParams {
  amount: number;
  providerId: string;
  dealId: string;
}

export interface InvestorApiControllerGetExpectedRepaymentsInfoParams {
  amount?: number;
  providerId: string;
  dealId: string;
}

export interface InvestorApiControllerGetInvestorEventsParams {
  types: string[];
}

export interface InvestorApiControllerExportDealsParams {
  id: string;
  providerId: string[];
  productType: string[];
  rating: string[];
  minDuration: number;
  maxDuration: number;
  currency: string[];
  minPrincipal: number;
  maxPrincipal: number;
  sortBy: string;
  sortOrder: string;
}

export interface InvestorApiControllerGetProfitAndLossStatisticsParams {
  /** @format date-time */
  from?: string;

  /** @format date-time */
  to?: string;
}

export interface InvestorApiControllerGetRepaymentStatisticsParams {
  /** @format date-time */
  from: string;

  /** @format date-time */
  to: string;
  currency: "CHF" | "EUR";
}

export interface InvestorApiControllerGetInvestmentSummaryParams {
  productType?: "CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC";
  currency?: "CHF" | "EUR";
}

export interface InvestorApiControllerGetPortfolioAllocationStatisticsParams {
  productType?: "CORPORATE" | "PRIVATE" | "REAL_ESTATE" | "TBC";
  currency?: "CHF" | "EUR";
  rating?: "AAA" | "AA" | "A" | "B" | "C" | "D" | "E";
  overdue?: "OnTime" | "Overdue30" | "Overdue60" | "Overdue120" | "TechnicalDefault" | "Loss";
}

export interface InvestorApiControllerGetTransactionsParams {
  type?: "PAYIN" | "PAYOUT";
  method?: "BANK_TRANSFER" | "CASH" | "DEBIT_DIRECT" | "LSV";
  currency: "CHF" | "EUR";
}

export interface InvestorApiControllerGenerateLsvddContractParams {
  currency: "CHF" | "EUR";
  iban: string;
  language: string;
}

export interface UserApiControllerGetUserDetailsParams {
  withAccounts?: boolean;
}

export interface UserApiControllerGetPossibleAccountInheritorsParams {
  investorId: string;
}

export interface UserApiControllerResendEmailVerificationLinkParams {
  /** Email is optional */
  email?: string;
}

export interface KycApiControllerFindUncompletedTaskGroupsByKycRecordParams {
  lang: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Marketplace investor API
 * @version 1.0
 * @contact
 *
 * The Marketplace API. Used by investors to find and finance deals
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  investors = {
    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetCurrentAccount
     * @summary Get current investor account
     * @request GET:/investors/account
     * @secure
     */
    investorApiControllerGetCurrentAccount: (params: RequestParams = {}) =>
      this.request<InvestorDto, any>({
        path: `/investors/account`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerUpdateCurrentAccount
     * @summary Update current investor account
     * @request PUT:/investors/account
     * @secure
     */
    investorApiControllerUpdateCurrentAccount: (data: UpdateInvestorDto, params: RequestParams = {}) =>
      this.request<InvestorDto, any>({
        path: `/investors/account`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetDeals
     * @summary List deals available to that investor, where he can currently invest
     * @request GET:/investors/deals
     * @secure
     */
    investorApiControllerGetDeals: (query: InvestorApiControllerGetDealsParams, params: RequestParams = {}) =>
      this.request<{ items?: IDealDto[]; meta?: { pageSize?: number; pageIndex?: number; totalItems?: number } }, any>({
        path: `/investors/deals`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetBulkDeal
     * @summary Get a specific deals by id pairs
     * @request POST:/investors/deals/by-ids
     * @secure
     */
    investorApiControllerGetBulkDeal: (data: DealsListDto, params: RequestParams = {}) =>
      this.request<IDealDto[], any>({
        path: `/investors/deals/by-ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetDealsByContinuationToken
     * @summary Get deals available to that investor with continuation token (for infinite scroll)
     * @request GET:/investors/deals/by-continuation-token
     * @secure
     */
    investorApiControllerGetDealsByContinuationToken: (
      query: InvestorApiControllerGetDealsByContinuationTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<{ data: IDealDto[]; continuationToken: string; total: number }, any>({
        path: `/investors/deals/by-continuation-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetDeal
     * @summary Get a specific deal
     * @request GET:/investors/deals/{providerId}/{id}
     * @secure
     */
    investorApiControllerGetDeal: (
      { providerId, id, ...query }: InvestorApiControllerGetDealParams,
      params: RequestParams = {},
    ) =>
      this.request<IDealDto, any>({
        path: `/investors/deals/${providerId}/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetDealPaymentHistory
     * @summary Get payment history as a list of events for a specific deal
     * @request GET:/investors/deals/{providerId}/{id}/payment-history
     * @secure
     */
    investorApiControllerGetDealPaymentHistory: (id: string, providerId: string, params: RequestParams = {}) =>
      this.request<DealEventDto[], any>({
        path: `/investors/deals/${providerId}/${id}/payment-history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all filters for deals
     *
     * @tags investors
     * @name InvestorApiControllerGetFilters
     * @summary Gets possible filters
     * @request GET:/investors/filters
     * @secure
     */
    investorApiControllerGetFilters: (params: RequestParams = {}) =>
      this.request<FilterDto, any>({
        path: `/investors/filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetShares
     * @summary List of the investor shares
     * @request GET:/investors/shares
     * @secure
     */
    investorApiControllerGetShares: (query: InvestorApiControllerGetSharesParams, params: RequestParams = {}) =>
      this.request<
        { items?: InvestmentWithDealDto[]; meta?: { pageSize?: number; pageIndex?: number; totalItems?: number } },
        any
      >({
        path: `/investors/shares`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetCommitments
     * @summary List of the investor commitments
     * @request GET:/investors/commitments
     * @secure
     */
    investorApiControllerGetCommitments: (
      query: InvestorApiControllerGetCommitmentsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        { items?: InvestmentWithDealDto[]; meta?: { pageSize?: number; pageIndex?: number; totalItems?: number } },
        any
      >({
        path: `/investors/commitments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetCommitmentsByContinuationToken
     * @summary Get investor commitments with continuation token (for infinite scroll)
     * @request GET:/investors/commitments/by-continuation-token
     * @secure
     */
    investorApiControllerGetCommitmentsByContinuationToken: (
      query: InvestorApiControllerGetCommitmentsByContinuationTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<{ data: InvestmentWithDealDto[]; continuationToken: string; total: number }, any>({
        path: `/investors/commitments/by-continuation-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerDownloadCommitments
     * @summary Download list of commitments (unpaid shares) as csv
     * @request GET:/investors/commitments/download
     * @secure
     */
    investorApiControllerDownloadCommitments: (
      query: InvestorApiControllerDownloadCommitmentsParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/investors/commitments/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestments
     * @summary List of the investor investments (commitments and shares)
     * @request GET:/investors/investments
     * @secure
     */
    investorApiControllerGetInvestments: (
      query: InvestorApiControllerGetInvestmentsParams,
      params: RequestParams = {},
    ) =>
      this.request<
        { items?: InvestmentWithDealDto[]; meta?: { pageSize?: number; pageIndex?: number; totalItems?: number } },
        any
      >({
        path: `/investors/investments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestmentsByContinuationToken
     * @summary Get investor investments (commitments and shares) with continuation token (for infinite scroll)
     * @request GET:/investors/investments/by-continuation-token
     * @secure
     */
    investorApiControllerGetInvestmentsByContinuationToken: (
      query: InvestorApiControllerGetInvestmentsByContinuationTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<{ data: InvestmentWithDealDto[]; continuationToken: string; total: number }, any>({
        path: `/investors/investments/by-continuation-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerDownloadInvestments
     * @summary Download list of investments (commitments and shares) as csv(s)
     * @request GET:/investors/investments/download
     * @secure
     */
    investorApiControllerDownloadInvestments: (
      query: InvestorApiControllerDownloadInvestmentsParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/investors/investments/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestment
     * @summary Get investment by id
     * @request GET:/investors/investments/{investmentId}
     * @secure
     */
    investorApiControllerGetInvestment: (
      { investmentId, ...query }: InvestorApiControllerGetInvestmentParams,
      params: RequestParams = {},
    ) =>
      this.request<InvestmentWithDealDto, any>({
        path: `/investors/investments/${investmentId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestmentEvents
     * @summary Get investment related events
     * @request GET:/investors/investments/{investmentId}/events
     * @secure
     */
    investorApiControllerGetInvestmentEvents: (
      { investmentId, ...query }: InvestorApiControllerGetInvestmentEventsParams,
      params: RequestParams = {},
    ) =>
      this.request<DealEventDto[], any>({
        path: `/investors/investments/${investmentId}/events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestmentRepaymentDetails
     * @summary Get investment repayment details
     * @request GET:/investors/investments/{investmentId}/repayment-details
     * @deprecated
     * @secure
     */
    investorApiControllerGetInvestmentRepaymentDetails: (investmentId: string, params: RequestParams = {}) =>
      this.request<RepaymentDetailsDto, any>({
        path: `/investors/investments/${investmentId}/repayment-details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerPayInvestment
     * @summary Pay an investment
     * @request POST:/investors/{providerId}/{id}/investments/{investmentId}/pay
     * @secure
     */
    investorApiControllerPayInvestment: (
      providerId: string,
      id: string,
      investmentId: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/investors/${providerId}/${id}/investments/${investmentId}/pay`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerCommitInvestment
     * @summary Commit an investment
     * @request POST:/investors/{providerId}/{id}/investments
     * @secure
     */
    investorApiControllerCommitInvestment: (
      providerId: string,
      id: string,
      data: CreateInvestmentDto,
      params: RequestParams = {},
    ) =>
      this.request<ShareCommitmentDto, any>({
        path: `/investors/${providerId}/${id}/investments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerSellShare
     * @summary Put an investment on sale
     * @request POST:/investors/{providerId}/{id}/sales
     * @secure
     */
    investorApiControllerSellShare: (
      providerId: string,
      id: string,
      data: CreateShareSaleDto,
      params: RequestParams = {},
    ) =>
      this.request<ShareSaleDto, any>({
        path: `/investors/${providerId}/${id}/sales`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerCancelInvestmentSale
     * @summary Cancel a secondary market investment sale
     * @request DELETE:/investors/{providerId}/{id}/sales
     * @secure
     */
    investorApiControllerCancelInvestmentSale: (providerId: string, id: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/investors/${providerId}/${id}/sales`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetShareSaleSellerPrice
     * @summary Get price of potential share sale
     * @request GET:/investors/{providerId}/{id}/saleprice
     * @secure
     */
    investorApiControllerGetShareSaleSellerPrice: (
      { providerId, id, ...query }: InvestorApiControllerGetShareSaleSellerPriceParams,
      params: RequestParams = {},
    ) =>
      this.request<ShareSalePriceDto, any>({
        path: `/investors/${providerId}/${id}/saleprice`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetSecondarymarketOffers
     * @summary Get secondary market offers
     * @request GET:/investors/secondarymarket
     * @secure
     */
    investorApiControllerGetSecondarymarketOffers: (
      query: InvestorApiControllerGetSecondarymarketOffersParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: ShareSaleWithShareAndDealDto[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
        },
        any
      >({
        path: `/investors/secondarymarket`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetSecondarymarketOffersByContinuationToken
     * @summary Get secondary market offers with continuation token (for infinite scroll)
     * @request GET:/investors/secondarymarket/by-continuation-token
     * @secure
     */
    investorApiControllerGetSecondarymarketOffersByContinuationToken: (
      query: InvestorApiControllerGetSecondarymarketOffersByContinuationTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<{ data: ShareSaleWithShareAndDealDto[]; continuationToken: string; total: number }, any>({
        path: `/investors/secondarymarket/by-continuation-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestorsSecondarymarketOffers
     * @summary Get your secondary market offers
     * @request GET:/investors/mysecondarymarket
     * @secure
     */
    investorApiControllerGetInvestorsSecondarymarketOffers: (
      query: InvestorApiControllerGetInvestorsSecondarymarketOffersParams,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items?: ShareSaleWithShareAndDealDto[];
          meta?: { pageSize?: number; pageIndex?: number; totalItems?: number };
        },
        any
      >({
        path: `/investors/mysecondarymarket`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerBuyShare
     * @summary Buy a share sale
     * @request POST:/investors/{providerId}/{id}/sales/{sellerInvestorId}/buy
     * @secure
     */
    investorApiControllerBuyShare: (
      providerId: string,
      sellerInvestorId: string,
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/investors/${providerId}/${id}/sales/${sellerInvestorId}/buy`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestorsSecondarymarketOffersByContinuationToken
     * @summary Get investor secondary market offers with continuation token (for infinite scroll)
     * @request GET:/investors/mysecondarymarket/by-continuation-token
     * @secure
     */
    investorApiControllerGetInvestorsSecondarymarketOffersByContinuationToken: (
      query: InvestorApiControllerGetInvestorsSecondarymarketOffersByContinuationTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<{ data: ShareSaleWithShareAndDealDto[]; continuationToken: string; total: number }, any>({
        path: `/investors/mysecondarymarket/by-continuation-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerCommitBulkInvestment
     * @summary Commit multiple investments
     * @request POST:/investors/bulk-investments
     * @secure
     */
    investorApiControllerCommitBulkInvestment: (data: BulkCreateInvestmentsDto, params: RequestParams = {}) =>
      this.request<BulkCreateInvestmentsResponseDto, any>({
        path: `/investors/bulk-investments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetExpectedXirr
     * @summary Get expected xirr for a potential investment
     * @request GET:/investors/{providerId}/{dealId}/xirr
     * @secure
     */
    investorApiControllerGetExpectedXirr: (
      { providerId, dealId, ...query }: InvestorApiControllerGetExpectedXirrParams,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/investors/${providerId}/${dealId}/xirr`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetExpectedRepaymentsInfo
     * @summary Get expected repayments info for a potential investment
     * @request GET:/investors/{providerId}/{dealId}/repayments
     * @secure
     */
    investorApiControllerGetExpectedRepaymentsInfo: (
      { providerId, dealId, ...query }: InvestorApiControllerGetExpectedRepaymentsInfoParams,
      params: RequestParams = {},
    ) =>
      this.request<TransactionsInfoDto, any>({
        path: `/investors/${providerId}/${dealId}/repayments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerInviteUser
     * @summary Invites a user to current investor account
     * @request POST:/investors/users
     * @secure
     */
    investorApiControllerInviteUser: (data: UserInviteDto, params: RequestParams = {}) =>
      this.request<InvestorUserAccessDto, any>({
        path: `/investors/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestorUsers
     * @summary List users involved with current investor account
     * @request GET:/investors/users
     * @secure
     */
    investorApiControllerGetInvestorUsers: (params: RequestParams = {}) =>
      this.request<InvestorUserAccessDto[], any>({
        path: `/investors/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerUnassignUserRole
     * @summary Removes user access from current investment account
     * @request DELETE:/investors/users/{userId}
     * @secure
     */
    investorApiControllerUnassignUserRole: (
      userId: string,
      data: UserInvestorAssignmentDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/investors/users/${userId}`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerChangeRole
     * @summary Change user role for current investment account
     * @request POST:/investors/users/{userId}/change-role
     * @secure
     */
    investorApiControllerChangeRole: (userId: string, data: InvestorAccountChangeRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/investors/users/${userId}/change-role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Will be moved to /investors/ in the nearest feature
     *
     * @tags investors
     * @name InvestorApiControllerGetPossibleAccountInheritors
     * @summary Get a list of all possible account owners except current user
     * @request GET:/investors/users/{userId}/inheritors
     * @secure
     */
    investorApiControllerGetPossibleAccountInheritors: (userId: string, params: RequestParams = {}) =>
      this.request<InvestorAccountInheritorsDto, any>({
        path: `/investors/users/${userId}/inheritors`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetUserDealFilters
     * @summary Get user deal filters
     * @request GET:/investors/users/deal-filters
     * @secure
     */
    investorApiControllerGetUserDealFilters: (params: RequestParams = {}) =>
      this.request<Record<string, UserDealFilterDto>, any>({
        path: `/investors/users/deal-filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerUpsertUserDealFilter
     * @summary Upsert user deal filter
     * @request POST:/investors/users/deal-filters
     * @secure
     */
    investorApiControllerUpsertUserDealFilter: (data: UserDealFilterDto, params: RequestParams = {}) =>
      this.request<Record<string, UserDealFilterDto>, any>({
        path: `/investors/users/deal-filters`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerUpsertUserDealFiltersBulk
     * @summary Upsert user multiple deal filters
     * @request POST:/investors/users/deal-filters-bulk
     * @secure
     */
    investorApiControllerUpsertUserDealFiltersBulk: (data: UpsertUserDealFiltersBulkDto, params: RequestParams = {}) =>
      this.request<Record<string, UserDealFilterDto>, any>({
        path: `/investors/users/deal-filters-bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerRemoveUserDealFilter
     * @summary Remove user deal filter by name
     * @request DELETE:/investors/users/deal-filters/{name}
     * @secure
     */
    investorApiControllerRemoveUserDealFilter: (name: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/investors/users/deal-filters/${name}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetUserPortfolioFilters
     * @summary Get user portfolio filters
     * @request GET:/investors/users/portfolio-filters
     * @secure
     */
    investorApiControllerGetUserPortfolioFilters: (params: RequestParams = {}) =>
      this.request<Record<string, UserDealFilterDto>, any>({
        path: `/investors/users/portfolio-filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerCreateUserPortfolioFilter
     * @summary Create user portfolio filter
     * @request POST:/investors/users/portfolio-filters
     * @secure
     */
    investorApiControllerCreateUserPortfolioFilter: (data: UserDealFilterDto, params: RequestParams = {}) =>
      this.request<Record<string, UserDealFilterDto>, any>({
        path: `/investors/users/portfolio-filters`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerRemoveUserPortfolioFilter
     * @summary Remove user portfolio filter
     * @request DELETE:/investors/users/portfolio-filters/{name}
     * @secure
     */
    investorApiControllerRemoveUserPortfolioFilter: (name: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/investors/users/portfolio-filters/${name}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetHmacSignature
     * @summary Get hmac signature used by Notifications Widget
     * @request GET:/investors/signature
     * @secure
     */
    investorApiControllerGetHmacSignature: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/investors/signature`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerSynchronizeUserAccount
     * @summary Synchronize User account with Notifuse and other services
     * @request GET:/investors/synchronize
     * @secure
     */
    investorApiControllerSynchronizeUserAccount: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/investors/synchronize`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestorEvents
     * @summary Get investor related events for dashboard
     * @request GET:/investors/dashboard/events
     * @secure
     */
    investorApiControllerGetInvestorEvents: (
      query: InvestorApiControllerGetInvestorEventsParams,
      params: RequestParams = {},
    ) =>
      this.request<DealEventDto[], any>({
        path: `/investors/dashboard/events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetDealsMatchesFilters
     * @summary Get deals matches user filters for dashboard
     * @request GET:/investors/dashboard/deal-filters
     * @secure
     */
    investorApiControllerGetDealsMatchesFilters: (params: RequestParams = {}) =>
      this.request<DealMatchesFilterDto[], any>({
        path: `/investors/dashboard/deal-filters`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerExportDeals
     * @summary Export deals available to that investor, where he can currently invest
     * @request GET:/investors/deals/export
     * @secure
     */
    investorApiControllerExportDeals: (query: InvestorApiControllerExportDealsParams, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/investors/deals/export`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetGeneralStatistics
     * @summary Get general statistics of the investor Shares
     * @request GET:/investors/statistics/general
     * @secure
     */
    investorApiControllerGetGeneralStatistics: (params: RequestParams = {}) =>
      this.request<GeneralStatisticsResultDto, any>({
        path: `/investors/statistics/general`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetProfitAndLossStatistics
     * @summary Get Net P&L statistics of the investor
     * @request GET:/investors/statistics/profit-loss
     * @secure
     */
    investorApiControllerGetProfitAndLossStatistics: (
      query: InvestorApiControllerGetProfitAndLossStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<ProfitLossStatisticsResultDto, any>({
        path: `/investors/statistics/profit-loss`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetRepaymentStatistics
     * @summary Get Repayment plan statistics of the investor
     * @request GET:/investors/statistics/repayments
     * @secure
     */
    investorApiControllerGetRepaymentStatistics: (
      query: InvestorApiControllerGetRepaymentStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<RepaymentStatisticsResultDto[], any>({
        path: `/investors/statistics/repayments`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetInvestmentSummary
     * @summary Get Investment summary table statistics of the investor
     * @request GET:/investors/statistics/investment-summary
     * @secure
     */
    investorApiControllerGetInvestmentSummary: (
      query: InvestorApiControllerGetInvestmentSummaryParams,
      params: RequestParams = {},
    ) =>
      this.request<InvestmentSummaryResultDto, any>({
        path: `/investors/statistics/investment-summary`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetPortfolioAllocationStatistics
     * @summary Get Portfolio allocation statistics of the investor
     * @request GET:/investors/statistics/portfolio-allocation
     * @secure
     */
    investorApiControllerGetPortfolioAllocationStatistics: (
      query: InvestorApiControllerGetPortfolioAllocationStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<PortfolioAllocationResultDto, any>({
        path: `/investors/statistics/portfolio-allocation`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetBalance
     * @summary Get account balance
     * @request POST:/investors/account/balance
     * @secure
     */
    investorApiControllerGetBalance: (data: string[], params: RequestParams = {}) =>
      this.request<BalanceDto[], any>({
        path: `/investors/account/balance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetTransactions
     * @summary Get account transactions
     * @request GET:/investors/transactions
     * @secure
     */
    investorApiControllerGetTransactions: (
      query: InvestorApiControllerGetTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<TransactionDto[], any>({
        path: `/investors/transactions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGenerateLsvddContract
     * @summary Generate LSV/DD contract
     * @request GET:/investors/ddlsv-contract
     * @secure
     */
    investorApiControllerGenerateLsvddContract: (
      query: InvestorApiControllerGenerateLsvddContractParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/investors/ddlsv-contract`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGenerateBasketInvestorAgreement
     * @summary Generate a temporary contract or a potential investment
     * @request POST:/investors/{providerId}/{id}/investments/generateContract
     * @secure
     */
    investorApiControllerGenerateBasketInvestorAgreement: (
      providerId: string,
      id: string,
      data: CreateInvestmentDto,
      params: RequestParams = {},
    ) =>
      this.request<PricingInfo, any>({
        path: `/investors/${providerId}/${id}/investments/generateContract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGenerateSecondaryBasketInvestorAgreement
     * @summary Generate a temporary contract or a potential secondary market investment
     * @request POST:/investors/{id}/investments/generateSecondaryContract
     * @secure
     */
    investorApiControllerGenerateSecondaryBasketInvestorAgreement: (
      id: string,
      data: CreateInvestmentDto,
      params: RequestParams = {},
    ) =>
      this.request<PricingInfo, any>({
        path: `/investors/${id}/investments/generateSecondaryContract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags investors
     * @name InvestorApiControllerGetSecondaryMarketContract
     * @summary Download empty secondary market contract
     * @request GET:/investors/secondarymarketcontract
     * @secure
     */
    investorApiControllerGetSecondaryMarketContract: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/investors/secondarymarketcontract`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerGetToken
     * @summary Get an investor token
     * @request GET:/user/investor-token/{investorId}
     * @secure
     */
    userApiControllerGetToken: (investorId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/user/investor-token/${investorId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerGetUserDetails
     * @summary Get user details
     * @request GET:/user/details
     * @secure
     */
    userApiControllerGetUserDetails: (query: UserApiControllerGetUserDetailsParams, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/user/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerUpdateUserDetails
     * @summary Update user details
     * @request POST:/user/details
     * @secure
     */
    userApiControllerUpdateUserDetails: (data: UserUpdateDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/user/details`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerGetInvestorAccounts
     * @summary Get all investors the current user has access to
     * @request GET:/user/investors
     * @secure
     */
    userApiControllerGetInvestorAccounts: (params: RequestParams = {}) =>
      this.request<InvestorUserAccessDto[], any>({
        path: `/user/investors`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerCreateInvestorAccount
     * @summary Create Fresh  investor account
     * @request POST:/user/investors
     * @secure
     */
    userApiControllerCreateInvestorAccount: (data: CreateInvestorDto, params: RequestParams = {}) =>
      this.request<InvestorUserAccess, any>({
        path: `/user/investors`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerUpdatePassword
     * @summary Change user password
     * @request POST:/user/password
     * @secure
     */
    userApiControllerUpdatePassword: (data: ChangePasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerSetPrimaryAccount
     * @summary Set primary account to the current user
     * @request POST:/user/primary-account
     * @secure
     */
    userApiControllerSetPrimaryAccount: (data: InvestorIdDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/primary-account`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Will be moved to /investors/ in the nearest feature
     *
     * @tags user
     * @name UserApiControllerRemoveAccount
     * @summary Remove yourself from the investor account
     * @request DELETE:/user/remove-account
     * @secure
     */
    userApiControllerRemoveAccount: (data: UserRemoveAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/remove-account`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Will be moved to /investors/ in the nearest feature
     *
     * @tags user
     * @name UserApiControllerGetPossibleAccountInheritors
     * @summary Get a list of all possible account owners except current user
     * @request GET:/user/account-inheritors
     * @secure
     */
    userApiControllerGetPossibleAccountInheritors: (
      query: UserApiControllerGetPossibleAccountInheritorsParams,
      params: RequestParams = {},
    ) =>
      this.request<InvestorAccountInheritorsDto, any>({
        path: `/user/account-inheritors`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Will send email verification to the provided email address.
     *
     * @tags user
     * @name UserApiControllerRequestChangeEmail
     * @summary Send request to change email address
     * @request POST:/user/change-email/request
     * @secure
     */
    userApiControllerRequestChangeEmail: (data: ChangeUserEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/change-email/request`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerVerifyChangeEmail
     * @summary Verify email change by providing verification token
     * @request POST:/user/change-email/verify
     * @secure
     */
    userApiControllerVerifyChangeEmail: (data: VerifyChangeUserEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/change-email/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerAcceptTermsAndConditions
     * @summary Accept privacy statement and terms and condition
     * @request POST:/user/acceptterms
     * @secure
     */
    userApiControllerAcceptTermsAndConditions: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/acceptterms`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerGetTermsAndConditions
     * @summary Get terms and conditions
     * @request GET:/user/acceptterms
     * @secure
     */
    userApiControllerGetTermsAndConditions: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/user/acceptterms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserApiControllerResendEmailVerificationLink
     * @summary Resend email verification link
     * @request POST:/user/email/link
     * @secure
     */
    userApiControllerResendEmailVerificationLink: (
      query: UserApiControllerResendEmailVerificationLinkParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/user/email/link`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  kyc = {
    /**
     * No description
     *
     * @tags kyc
     * @name KycApiControllerGenerateTaskLink
     * @summary Generate link for current task
     * @request GET:/kyc/g-current
     * @secure
     */
    kycApiControllerGenerateTaskLink: (params: RequestParams = {}) =>
      this.request<PricingInfo, any>({
        path: `/kyc/g-current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc
     * @name KycApiControllerFindKycRecordById
     * @summary Get kyc record by id
     * @request GET:/kyc
     * @secure
     */
    kycApiControllerFindKycRecordById: (params: RequestParams = {}) =>
      this.request<KycRecord, any>({
        path: `/kyc`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags kyc
     * @name KycApiControllerFindUncompletedTaskGroupsByKycRecord
     * @summary Get uncompleted tasks by kyc record id
     * @request GET:/kyc/groups/uncompleted
     * @secure
     */
    kycApiControllerFindUncompletedTaskGroupsByKycRecord: (
      query: KycApiControllerFindUncompletedTaskGroupsByKycRecordParams,
      params: RequestParams = {},
    ) =>
      this.request<object[], any>({
        path: `/kyc/groups/uncompleted`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
